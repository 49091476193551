//
//
//
//

import { defineComponent, useContext, useRoute } from '@nuxtjs/composition-api'
import useSiteCookies from '~/composables/useSiteCookies'

export default defineComponent({
  setup () {
    const route = useRoute()
    const { $cms } = useContext()
    const { setLocaleCookie } = useSiteCookies()

    const provideLocaleAsCookie = async () => {
      const lang = await $cms.getLanguageFromRoute({ route: route.value })
      setLocaleCookie(lang || $cms.defaultLanguageCode)
    }

    provideLocaleAsCookie()
  }
})
